.FAQ {
  display: flex;
  justify-content: center;
  padding-top: 42px;
  padding-bottom: 46px;
  padding-left: 100px;
  padding-right: 100px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/images/faq-background.jpg);
  background-size: cover;

  h1 {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    max-width: 1240px;
  }
}

@media (max-width: 1024px) {
  .FAQ {
    display: block;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    h1 {
      display: block;
      margin: 0;
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      color: white;
    }
    .container {
      max-width: unset;
    }
  }
}
