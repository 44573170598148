.BuyOrRentPage {
  min-height: calc(100vh - 182px);

  .filtersContainer {
    display: flex;
    justify-content: center;
    height: 560px;
    padding-top: 219px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(../../assets/images/main-background.jpg);
    background-size: cover;
  }

  .mainContent {
    padding-top: 64px;
    padding-bottom: 96px;
    .propertyListContainer {
      display: flex;
      justify-content: center;
      padding-left: 100px;
      padding-right: 100px;

      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BuyOrRentPage {
    min-height: unset;

    .filtersContainer {
      height: unset;
      padding-top: 77px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      background-position: center;
    }

    .mainContent {
      padding-top: 30px;
      padding-bottom: 30px;
      .propertyListContainer {
        display: block;
        padding-left: 20px;
        padding-right: 0;

        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}
