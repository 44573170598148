.PropertyListPage {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 182px);
  padding-top: 120px;
  padding-left: 100px;
  padding-right: 100px;

  .container {
    display: flex;
    justify-content: center;
    flex: 1;
    max-width: 1240px;

    form {
      display: flex;
      align-items: center;
      flex: 1;
      height: 42px;
      padding-top: 45px;

      label {
        display: flex;
        align-items: center;
        margin-right: 20px;
        input {
          margin-left: 10px;
        }
      }
    }

    ul {
      flex: 1;
      max-width: 640px;
      margin: 0;
      padding: 0;
      padding-top: 40px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          background-color: lightgrey;
          svg {
            display: unset;
          }
        }

        svg {
          display: none;
        }
      }
    }
  }
}
