.WhatWeSellPage {
  min-height: calc(100vh - 182px);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 64px;
    // padding-left: 100px;
    // padding-right: 100px;
    // padding-bottom: 36px;

    .mostPopular {
      max-width: 1240px;
      width: 100%;
      margin-bottom: 64px;

      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        color: #ef5225;
      }

      .posts {
        display: flex;
        gap: 60px;

        .list {
          display: flex;
          flex-direction: column;
          gap: 31px;
          flex: 1;
        }
      }
    }

    .blogPostsList {
      max-width: 1240px;
      width: 100%;

      h2 {
        margin: 0;
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        color: #ef5225;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 64px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .WhatWeSellPage {
    min-height: unset;

    .container {
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;

      .mostPopular {
        max-width: unset;
        margin-bottom: 30px;
        h2 {
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 36px;
          line-height: 43px;
        }

        .posts {
          display: block;
          .list {
            gap: 20px;
            padding-top: 30px;
          }
        }
      }

      .blogPostsList {
        max-width: unset;
        h2 {
          margin-bottom: 30px;
          font-weight: 600;
          font-size: 36px;
          line-height: 43px;
        }

        ul {
          flex-direction: column;
          gap: 20px;
          li {
            margin-bottom: 0;
            display: flex;
            width: 100%;
          }
        }
      }
    }
  }
}

.blogItem {
  min-height: calc(100vh - 182px);

  .container {
    display: flex;
    justify-content: center;
    // padding-right: 50px;
    // padding-left: 50px;
    .content {
      max-width: 1240px;
      flex: 1;
      // padding-top: 128px;
      // padding-bottom: 100px;

      h1 {
        margin: 0;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 34px;
        line-height: 48px;
        color: #ef5225;
      }

      img {
        width: 566px;
        height: 393px;
        margin-right: 44px;
        float: left;
        object-fit: cover;
      }

      .textContent {
        p {
          margin: 0;
          margin-bottom: 26px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 29px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .blogItem {
    min-height: unset;

    * {
      overflow-x: hidden;
    }

    .container {
      .content {
        max-width: unset;
        padding-top: 30px;
        // padding-right: 20px;
        // padding-left: 20px;
        padding-bottom: 4px;
        h1 {
        }

        img {
          width: 100%;
          height: unset;
          float: unset;
        }

        .textContent {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
