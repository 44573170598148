.Property {
  width: 185px;

  header {
    position: relative;
    width: 100%;
    height: 139px;
    margin-bottom: 10px;
    background-size: cover;
    border-radius: 6px;

    button {
      position: absolute;
      top: 100px;
      right: 10px;
      width: 71px;
      height: 29px;
      font-family: 'Smooch Sans', 'Alumni Sans';
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: white;
      background-color: #ef5225;
      border-radius: 6px;
      border: none;
    }
  }

  .propertyTitle {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    overflow: hidden;
  }

  .description {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}
