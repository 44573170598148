.Item {
  width: 100%;
  display: block;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;

    img {
      width: 70%;
      aspect-ratio: 1;
      margin-bottom: 5px;
      object-fit: cover;
      border-radius: 50%;
    }

    h1 {
      font-family: 'Montserrat', sans-serif;
      font-size: 24px !important;
      margin: 0;
      font-size: 26px;
    }
  }
}
