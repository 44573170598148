.TextInput {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 148%;
    color: rgba(255, 255, 255, 0.7);
  }

  input {
    font-family: 'Smooch Sans', 'Alumni Sans';
    font-weight: 600;
    font-size: 22px;
    line-height: 148%;
    color: white;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #d9d9d9;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: white;
      opacity: 1;
    }
  }
}

@media (max-width: 1024px) {
  .TextInput {
    .label {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    input {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
