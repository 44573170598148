.Header {
  font-family: 'Smooch Sans', 'Alumni Sans', sans-serif;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 114px;
  //padding-top: 35px;
  padding-left: 180px;
  padding-right: 180px;
  background-color: rgba(0, 0, 0, 0.7);

  &.russian {
    .container {
      .navigationMenu {
        max-width: 1000px;
      }
    }
  }

  .languageSwitcher {
    position: absolute;
    right: 40px;
    top: 40px;
    display: flex;
    gap: 5px;
    font-family: 'Smooch Sans';
    font-weight: bold;
    font-size: 28px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;

    img {
      width: 20px;
    }

    .languagesList {
      position: absolute;
      top: 40px;
      left: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% + 20px);
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #262626;
      border-radius: 4px;

      div {
        display: flex;
        gap: 5px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 2px;
        background-color: #ef5225;
        border-radius: 4px;
      }
    }
  }

  .menuButton {
    display: none;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 20px;
    z-index: 22;
    width: 150px;
  }

  .container {
    display: flex;
    flex: 1;
    max-width: 1240px;
    align-items: center;

    .phone {
      margin-left: auto;
      padding-left: 20px;
      font-family: 'Smooch Sans';
      text-decoration: none;
      font-weight: 600;
      font-size: 22px;
      line-height: 148%;
      color: white;
    }

    .navigationMenu {
      flex: 1;
      max-width: 835px;
      margin-left: 40px;
      ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          a {
            text-decoration: none;
            font-weight: 600;
            font-size: 30px;
            line-height: 148%;
            color: white;
            opacity: 0.6;

            &.link {
              opacity: 1;
            }

            &:hover {
              opacity: 1;
            }

            &.active {
              position: relative;
              opacity: 1;

              &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 16px;
                height: 2px;
                background-color: #ef5225;
                border-radius: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Header {
    height: 47px;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .languageSwitcher {
      top: 10px;
      right: 64px;
      font-weight: 600;
      font-size: 18px;
      line-height: 148%;
    }

    .menuButton {
      display: block;
      display: flex;
      align-items: center;
      padding-right: 23px;
      button {
        background-color: transparent;
        padding: 0;
        border: none;
      }
    }

    .logo {
      top: 2px;
      width: 54px;
    }

    .container {
      display: flex;
      align-items: center;
      .phone {
        margin-right: 80px;
        padding-left: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 148%;
        white-space: nowrap;
      }

      .navigationMenu {
        display: none;
      }
    }
  }
}
