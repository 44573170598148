.Select {
  position: relative;
  width: 100%;

  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 148%;
    color: rgba(255, 255, 255, 0.7);
    user-select: none;
  }

  .selector {
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 148%;
    color: white;
    background-image: url(../../assets/images/selectArrow.svg);
    background-repeat: no-repeat;
    background-position: right;
    border-bottom: 1px solid #d9d9d9;
    user-select: none;
    cursor: pointer;

    &.open {
      border-bottom: 2px solid #ef5225;
    }
  }

  .options {
    position: absolute;
    left: -2px;
    right: -2px;
    z-index: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 148%;
    color: white;
    background-color: #262626;
    border-radius: 4px;

    .option {
      display: flex;
      align-items: center;
      height: 38px;
      padding-left: 16px;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        background-color: #3e3e3e;
        opacity: 1;
        border-radius: 4px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Select {
    .label {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .selector {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
