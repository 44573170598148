.ImageGallery {
  display: flex;
  .currentImage {
    position: relative;
    display: flex;
    flex: 1;
    max-width: 925px;
    max-height: 651.5px;
    overflow: hidden;

    .images {
      position: relative;
      display: flex;

      img {
        min-width: 100%;
        max-width: 100%;
        height: 653px;
        object-fit: cover;
      }
    }
    button {
      position: absolute;
      z-index: 1;
      top: calc(50% - 40px);
      width: 70px;
      height: 80px;
      background: #000000;
      opacity: 0.6;
      border-radius: 8px;
      border: none;
      cursor: pointer;

      &.next {
        right: 0;
      }
    }
  }

  .thumbnails {
    display: flex;
    flex-direction: column;
    gap: 21px;
    flex: 1;
    max-width: 295px;
    height: 653px;
    margin-left: 21px;
    overflow-y: auto;

    img {
      width: 100%;
      max-height: 204px;
      min-height: 203px;
      object-fit: cover;
    }
  }
}
