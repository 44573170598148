.BlogButton {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 60px;
  background-color: transparent;
  cursor: pointer;
  transition: width 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &.hovered {
    width: 210px;
    span {
      right: 40px;
      opacity: 1;
    }
  }
  .buttonContainer {
    position: relative;
    padding-right: 3px;
    height: 60px;
    background-color: white;
    .button {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      min-width: 60px;
      height: 60px;
      padding: 0;
      color: white;
      border-radius: 5px;
      border: none;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        position: relative;
      }
    }
    .activeButton {
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      min-width: 60px;
      height: 60px;
      padding: 0;
      color: white;
      border-radius: 5px;
      border: none;
      // overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
      }
      &::before {
        content: '';
        display: block;
        width: 120%;
        height: 120%;
        background: #ef5225;
        filter: blur(8px);
        position: absolute;
        right: -4px;
        bottom: -5px;
      }
    }
  }
  span {
    position: absolute;
    z-index: 0;
    right: -110px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 60px;
    padding-right: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    color: white;
    opacity: 0;
    // transition: right 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    //   opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
