.Filters {
  display: flex;
  max-width: 1240px;
  height: 200px;
  flex: 1;
  gap: 20px;

  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
    max-width: 400px;

    .buttonContainer {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .shortSelects {
      display: flex;
      gap: 20px;
    }
  }
}

@media (max-width: 1024px) {
  .Filters {
    height: unset;
    flex-direction: column;
    .col {
      gap: 20px;
      .buttonContainer {
        button {
          width: 100%;
        }
      }
    }
  }
}
