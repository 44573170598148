.StickyMenu {
  position: absolute;
  top: 624px;
  right: 0;
  display: flex;
  padding-right: 40px;
  // background-color: transparent;
  // overflow: hidden;

  .cover {
    position: absolute;
    right: 0;
    z-index: 22;
    width: 40px;
    height: 100%;
    // background-color: white;
  }
}
