.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.window {
  width: 300px;
  padding: 15px;
  background-color: #fff;
  // border-radius: 10px;
}
