.PropertyList {
  flex: 1;
  max-width: 1240px;
  height: 558px;
  position: relative;
  h1 {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  .listContainer {
    // position: relative;
    .arrowButton {
      position: absolute;
      top: 130px;
      right: -40px;
      padding: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &.previous {
        right: unset;
        left: -40px;
      }
    }
    .list {
      margin: 0;
      padding: 0;
      list-style: none;
      overflow: hidden;
    }
    .inner {
      // position: relative;
      display: flex;
      gap: 20px;

      transition: all 0.2s;
    }
  }
}
