.AboutPage {
  //font-family: 'Open Sans', sans-serif;
  .container {
    display: flex;
    justify-content: center;
    padding-left: 100px;
    padding-right: 100px;

    .content {
      max-width: 1240px;
      flex: 1;
      padding-top: 64px;

      h1.mainHeading {
        margin: 0;
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 36px;
        line-height: 77px;
      }

      ol {
        margin: 0;
        margin-bottom: 64px;
        padding: 0;
        padding-left: 17px;

        li {
          font-weight: 500;
          font-size: 18px;
          line-height: 29px;

          &::marker {
            color: #ef5225;
          }
        }
      }

      .faq {
        h2 {
          margin: 0;
          margin-bottom: 16px;
          font-weight: 600;
          font-size: 36px;
          line-height: 77px;
        }
      }

      .blog {
        padding-bottom: 100px;
        h2 {
          margin: 0;
          margin-bottom: 16px;
          font-weight: 600;
          font-size: 36px;
          line-height: 77px;
        }
        ul {
          display: flex;
          column-gap: 20px;
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .AboutPage {
    .container {
      padding-left: 20px;
      padding-right: 20px;

      .content {
        padding-top: 30px;
        h1.mainHeading {
          display: none;
        }

        ol {
          display: none;
        }

        .faq {
          h2 {
            font-size: 30px;
          }
        }

        .blog {
          padding-bottom: 39px;
          h2 {
            margin-bottom: 30px;
            font-weight: 600;
            font-size: 30px;
            line-height: 43px;
          }
          ul {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
  }
}
