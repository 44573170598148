.Property {
  width: 400px;
  header {
    position: relative;
    width: 100%;
    height: 278px;
    margin-bottom: 20px;
    background-size: cover;

    button {
      position: absolute;
      top: 220px;
      right: 16px;
    }
  }

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    overflow: hidden;
  }

  .description {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
  }
}
