.PhoneInput {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 148%;
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (max-width: 1024px) {
  .PhoneInput {
    .label {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
