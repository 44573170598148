.header-background {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../assets/images/main-background.jpg);
  background-size: cover;
  border: 1px solid transparent;
}

.featuresContainer {
  display: flex;
  text-align: center;
}
.featuresContainerText {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.propertyContainer {
  display: flex;
}
.propertyContainerIcon {
  margin-right: 15px;
}
.propertyContainerDescription {
  display: flex;
  flex-direction: column;
}
.propertyContainerText {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}
.propertyContainerTextTitle {
  margin: 0;
  font-weight: bold;
}

.shareButton {
  width: 180px;
  min-height: 42px;
  max-height: 42px;
  font-family: 'Smooch Sans';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: white;
  background-color: #ef5225;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contactButton {
  width: 180px;
  min-height: 42px;
  max-height: 42px;
  font-family: 'Smooch Sans';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: white;
  background-color: #2aa81a;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PropertyPage {
  display: flex;
  justify-content: center;
  padding-top: 64px;
  padding-left: 100px;
  padding-right: 100px;
  font-family: 'Open Sans', sans-serif;
  .container {
    flex: 1;
    max-width: 1240px;

    .galleryContainer {
      height: 653px;
      margin-bottom: 32px;
    }

    .mainData {
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        h1 {
          margin: 0;

          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
        }

        .price {
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: #ef5225;
          white-space: nowrap;
        }
      }

      .buttons {
        display: flex;
        // justify-content: space-between;
        margin-bottom: 39px;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        .contactButtons {
          display: flex;
          gap: 15px;
          // flex-direction: column;
          align-items: flex-end;
        }
      }

      .specs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        .col {
          width: 50%;
          ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              font-weight: 600;
              font-size: 18px;
              line-height: 29px;
              text-transform: capitalize;
              sup {
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
              }
            }
          }
        }
      }

      .description {
        margin-bottom: 48px;
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
}

.similarQueries {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding-left: 100px;
  padding-right: 100px;
  font-family: 'Open Sans', sans-serif;

  h2 {
    width: 1240px;
    margin: 0;
    text-align: left;
    font-weight: 600;
    font-size: 34px;
    line-height: 60px;
    color: #ef5225;
  }
}

.propertyListContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 134px;
  padding-left: 100px;
  padding-right: 100px;

  & > div {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .PropertyPage {
    padding-top: 34px;
    padding-left: 20px;
    padding-right: 20px;
    .container {
      .galleryContainer {
        height: unset;
        margin-bottom: 10px;
        .titleImage {
          width: 100%;
          aspect-ratio: 387 / 252;
          object-fit: cover;
          border-radius: 6px;
        }
      }

      .description {
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }

      .restImages {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 30px;
        img {
          width: calc((100% - 20px) / 3);
          aspect-ratio: 123 / 92;
          object-fit: cover;
          border-radius: 6px;
        }
      }

      .mainData {
        header {
          display: block;
          h1 {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
          }

          .price {
            font-weight: 700;
            font-size: 36px;
            line-height: 43px;
          }
        }

        .buttons {
          margin-bottom: 20px;
          button {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
          }
        }

        .specs {
          flex-direction: column;
          gap: 10px;
          margin-bottom: 30px;
          .col {
            ul {
              gap: 10px;
            }
          }
        }
      }
    }
  }

  .similarQueries {
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 0;

    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 43px;
    }
  }

  .propertyListContainer {
    margin-bottom: 46px;
    padding-left: 20px;
    padding-right: 20px;

    .similarPropertyContainer {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .similarProp {
        header {
          position: relative;
          width: 100%;
          aspect-ratio: 387 / 252;
          margin-bottom: 10px;
          border-radius: 6px;
          overflow: hidden;

          button {
            position: absolute;
            z-index: 1;
            right: 10px;
            bottom: 10px;
            width: 106px;
            height: 37px;
            font-family: 'Smooch Sans', 'Alumni Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 23px;
            color: white;
            background-color: #ef5225;
            border-radius: 6px;
            border: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h1 {
          margin: 0;
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }
}

.modal {
  .title {
    font-size: 26px;
    margin: 0;
    text-align: center;
    font-family: 'Smooch Sans';
  }
  .social {
    display: flex;
    width: 100px;
    margin-top: 20px;
    gap: 15px;
    justify-content: center;
    width: 100%;

    div {
      cursor: pointer;
    }

    a {
      width: unset;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}
