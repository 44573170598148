.SellPage {
  min-height: calc(100vh - 182px);

  &.secondStep {
    .emptySpace {
      height: 639px;
    }
  }

  .formContainer {
    position: absolute;
    top: 310px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 615px;
  }

  .emptySpace {
    height: 667px;
  }
}

@media (max-width: 1024px) {
  .SellPage {
    min-height: unset;

    &.secondStep {
      .emptySpace {
        height: 598px;
      }
    }

    .formContainer {
      top: 112px;
      min-height: unset;
    }

    .emptySpace {
      height: 380px;
    }
  }
}
