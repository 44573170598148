.Footer {
  display: flex;
  font-family: 'Smooch Sans', 'Alumni Sans', sans-serif;
  justify-content: center;
  width: 100%;
  // height: 182px;
  padding-top: 32px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 22px;
  background-color: black;

  .mobileContainer {
    display: none;
  }

  .container {
    display: flex;
    max-width: 1240px;
    flex: 1;

    .logoContainer {
      position: relative;
      left: -40px;
      img {
        width: 134px;
        height: 116px;
      }
    }

    .cols {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding-right: 92px;
      .col {
        display: flex;
        flex-direction: column;

        ul {
          display: flex;
          flex-direction: column;
          gap: 4px;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            height: 24px;

            a {
              font-weight: 600;
              font-size: 22px;
              line-height: 148%;
              color: white;
              text-decoration: none;
            }
          }
        }

        &.first {
          padding-top: 36px;

          a {
            margin-bottom: 8px;
            font-family: 'Smooch Sans';
            font-weight: 600;
            font-size: 22px;
            line-height: 148%;
            color: white;
            text-decoration: none;
          }

          small {
            font-family: 'Smooch Sans';
            font-weight: 600;
            font-size: 22px;
            line-height: 148%;
            color: white;
          }
        }

        &.fourth {
          display: flex;
          flex-direction: column;
          gap: 8px;
          div,
          .socialLink {
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
            span {
              font-family: 'Smooch Sans';
              font-weight: 600;
              font-size: 22px;
              line-height: 148%;
              color: white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Footer {
    height: unset;
    padding: 20px;
    .container {
      display: none;
    }
    .mobileContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .logoContainer {
        width: 54px;

        img {
          width: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
      }

      .social {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
        a,
        .socialLink {
          display: flex;
          align-items: center;
          gap: 10px;
          img {
            width: 20px;
          }
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: white;
      }
    }
  }
}
