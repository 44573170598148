.HomePage {
  min-height: calc(100vh - 182px);

  .searchContainer {
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 560px;
    padding-top: 185px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(../../assets/images/main-background.jpg);
    background-size: cover;
    border: 1px solid transparent;

    .container {
      flex: 1;
      max-width: 1240px;

      h1 {
        margin: 0;
        padding-left: 10px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 100px;
        line-height: 120px;
        color: rgba(255, 255, 255, 0.6);

        span {
          color: #ef5225;
          opacity: 0.8;
        }
      }

      .text {
        position: relative;
        max-width: 681px;
        margin-left: auto;
        padding-top: 35px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 35px;

        .background {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 30px;
          width: 100%;
          //  height: 100%;
          background: rgba(239, 82, 37, 0.38);
          filter: blur(39px);
        }

        p {
          max-width: 681px;
          margin: 0;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 25px;
          color: white;

          &.hidden {
            visibility: hidden;
          }

          &.visible {
            position: absolute;
            top: 35px;
            left: 15px;
            visibility: visible;
          }
        }
      }

      .search {
        display: flex;
        justify-content: space-between;

        input {
          width: calc(100% - 20px - 65px);
          height: 65px;
          padding-left: 20px;
          padding-right: 20px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 24px;
          line-height: 29px;
          background-color: rgba(255, 255, 255, 0.6);
          border-radius: 8px;
          border: none;

          &:focus {
            outline: none;
          }
        }

        button {
          width: 65px;
          height: 65px;
          background-color: #ef5225;
          border-radius: 8px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  .info {
    position: relative;
    display: block;
    padding-top: 89px;
    padding-left: 100px;
    padding-right: 100px;

    .reasons {
      max-width: 1240px;
      margin-bottom: 68px;
      margin-left: auto;
      margin-right: auto;
      h2 {
        display: none;
        margin: 0;
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 36px;
        line-height: 77px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 49px;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: flex;
          align-items: center;
          gap: 24px;
          width: calc((100% - 40px) / 3);
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 18px;
          line-height: 29px;

          div {
            min-width: 80px;
            height: 80px;
            background-repeat: no-repeat;
            background-position: center;
            background-color: #ef5225;
            border-radius: 50%;

            &.first {
              background-image: url(../../assets/images/about/notes.svg);
            }

            &.second {
              background-image: url(../../assets/images/about/license.svg);
            }

            &.third {
              background-image: url(../../assets/images/about/handshake.svg);
            }

            &.fourth {
              background-image: url(../../assets/images/about/car.svg);
            }

            &.fifth {
              background-image: url(../../assets/images/about/building.svg);
            }

            &.sixth {
              background-image: url(../../assets/images/about/user.svg);
            }
          }
        }
      }
    }

    .videoContainer {
      position: relative;
      display: flex;
      justify-content: center;
      max-width: 870px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 44px;
      padding: 5px;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: #ef5225;
        filter: blur(10px);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        //z-index: 1;
      }
      .video {
        position: relative;
        width: 100%;
        max-width: 857px;
        height: auto;
        //z-index: 2;
      }
    }

    .carouselContainer {
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 72px;
    }

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
      flex: 1;
      // padding-top: 700px;

      .description {
        margin-bottom: 122px;
        margin-right: 80px;
        h2 {
          width: 485px;
          margin: 0;
          margin-bottom: 17px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          font-size: 34px;
          line-height: 44px;
        }

        ol {
          width: 100%;
          margin: 0;
          margin-bottom: 24px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 29px;
        }

        /*   p {
          width: 313px;
          margin: 0;
          margin-bottom: 24px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 29px;
        } */

        .buttons {
          display: flex;
          justify-content: space-between;
          width: 490px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .HomePage {
    min-height: unset;

    .searchContainer {
      top: 47px;
      height: 410px;
      padding-top: 68px;
      padding-left: 20px;
      padding-right: 20px;
      .container {
        h1 {
          padding-left: 0;
          font-family: 'Montserrat', sans-serif;
          font-weight: 800;
          font-size: 30px;
          line-height: 36px;
        }

        .text {
          max-width: 250px;
          padding-top: 15px;
          padding-bottom: 15px;

          .background {
            filter: blur(39px);
          }

          p {
            font-size: 16px;

            &.visible {
              top: 15px;
              left: 15px;
            }
          }
        }

        .search {
          justify-content: flex-start;
          input {
            width: 100%;
            height: 39px;
            background-color: white;
            border-radius: 6px 0px 0px 6px;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 39px;
            max-width: 39px;
            height: 39px;
            border-radius: 0px 6px 6px 0px;

            svg {
              width: 19px;
              height: 19px;
            }
          }
        }
      }
    }

    .info {
      padding-top: 45px;
      padding-left: 0;
      padding-right: 0;

      .reasons {
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
        h2 {
          margin-bottom: 30px;
          font-weight: 600;
          font-size: 30px;
          line-height: 36px;
        }

        ul {
          flex-direction: column;

          li {
            width: 100%;

            div {
              min-width: 60px;
              width: 60px;
              height: 60px;
              background-size: 35px;
            }
          }
        }
      }

      .videoContainer {
        display: flex;
        align-items: center;
        top: 15px;
        padding-left: 10px;
        padding-right: 10px;
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: #ef5225;
          filter: blur(10px);
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          //z-index: 1;
        }
        .video {
          position: relative;
          //width: 100%;
          //max-width: 857px;
          // height: auto;
          //z-index: 2;
        }
      }

      .carouselContainer {
        padding-left: 70px;
        padding-right: 70px;
        margin-bottom: 30px;
      }

      .container {
        display: block;
        max-width: 100vw;
        padding-left: 20px;
        padding-right: 20px;

        &.propertyList {
          padding-right: 0;
        }

        .description {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 100vw;
          margin: 0;
          margin-bottom: 20px;

          h2 {
            width: 100%;
            margin-bottom: 10px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            font-size: 26px;
            line-height: 34px;
            text-align: center;
            white-space: wrap;
          }

          ol {
            width: 100%;
            max-width: 313px;
            margin-bottom: 25px;
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            // text-align: center;
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            width: unset;
            width: 100%;
            max-width: 388px;

            button {
              flex: 1;
              max-width: 164px;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .mapContainer {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          border: 1px solid red;
        }
      }
    }
  }
}
