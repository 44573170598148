.Button {
  width: 237px;
  min-height: 42px;
  max-height: 42px;
  font-family: 'Smooch Sans';
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: white;
  background-color: #ef5225;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &.disabled {
    cursor: auto;
    opacity: 0.7;
  }
}
