.Question {
  .question {
    font-weight: 600;
    font-size: 24px;
    line-height: 148%;
    color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00005 9.50005C8.80823 9.50005 8.61623 9.42673 8.4698 9.2803L0.969797 1.7803C0.676734 1.48723 0.676734 1.01267 0.969797 0.719797C1.26286 0.426922 1.73742 0.426734 2.0303 0.719797L9.00005 7.68955L15.9698 0.719797C16.2629 0.426734 16.7374 0.426734 17.0303 0.719797C17.3232 1.01286 17.3234 1.48742 17.0303 1.7803L9.5303 9.2803C9.38386 9.42673 9.19186 9.50005 9.00005 9.50005Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right center;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;

    &.open {
      border-bottom: none;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99995 0.499953C9.19177 0.499953 9.38377 0.573265 9.5302 0.719703L17.0302 8.2197C17.3233 8.51277 17.3233 8.98733 17.0302 9.2802C16.7371 9.57308 16.2626 9.57327 15.9697 9.2802L8.99995 2.31045L2.0302 9.2802C1.73714 9.57327 1.26258 9.57327 0.969702 9.2802C0.676826 8.98714 0.67664 8.51258 0.969702 8.2197L8.4697 0.719703C8.61614 0.573265 8.80814 0.499953 8.99995 0.499953Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }

  .answer {
    position: relative;
    padding-top: 16px;
    padding-bottom: 17px;
    padding-left: 16px;
    padding-right: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 148%;
    color: white;
    background-color: #3e3e3e;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: #ef5225;
      border-radius: 4px;
    }
  }
}

@media (max-width: 1024px) {
  .Question {
    .question {
      padding-bottom: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .answer {
      font-size: 16px;
    }
  }
}
